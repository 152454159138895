<template>
  <v-container fluid>
    <PageNavigation :items="items" />

    <v-row>
      <v-col cols="12">
        <base-material-card
          :title="$vuetify.lang.t('$vuetify.supplier')"
          icon="mdi-account-multiple"
        >
          <v-row class="my-0">
            <v-col cols="12" lg="3" md="6" sm="12">
              <v-text-field
                :label="$vuetify.lang.t('$vuetify.search')"
                v-model="search"
                append-icon="mdi-magnify"
              />
            </v-col>

            <v-col cols="12" lg="3" md="6" sm="12">
              <v-select
                :label="$vuetify.lang.t('$vuetify.category')"
                prepend-inner-icon="mdi-magnify"
                :items="category"
                return-object
                @change="(item) => handleCategoryFilter(item)"
              />
            </v-col>

            <v-spacer />

            <v-col
              cols="12"
              lg="5"
              md="4"
              sm="12"
              align-self="center"
              class="d-flex justify-end"
              v-if="$vuetify.breakpoint.lgAndUp"
            >
              <v-btn
                v-if="permissionCheck('add')"
                color="green"
                elevation="2"
                @click="batchUpload"
              >
                <v-icon left>mdi-file-import</v-icon>
                {{ $vuetify.lang.t("$vuetify.batchUpload") }}
              </v-btn>

              <v-btn
                v-if="permissionCheck('add')"
                color="green"
                @click="categoryDialog = true"
              >
                <v-icon left>mdi-plus</v-icon>
                New Category
              </v-btn>

              <v-btn
                v-if="permissionCheck('add')"
                color="green"
                elevation="2"
                @click="handleDialog"
              >
                <v-icon left>mdi-account-plus</v-icon>
                {{ $vuetify.lang.t("$vuetify.add") }}
              </v-btn>
            </v-col>

            <v-col
              cols="12"
              align-self="center"
              class="d-flex justify-end"
              v-else
            >
              <v-btn
                v-if="permissionCheck('add')"
                color="green"
                elevation="2"
                @click="batchUpload"
              >
                <v-icon>mdi-file-import</v-icon>
              </v-btn>

              <v-btn
                v-if="permissionCheck('add')"
                color="green"
                @click="categoryDialog = true"
              >
                <v-icon>mdi-plus</v-icon>
              </v-btn>

              <v-btn
                v-if="permissionCheck('add')"
                color="green"
                elevation="2"
                @click="handleDialog"
              >
                <v-icon>mdi-account-plus</v-icon>
              </v-btn>
            </v-col>
          </v-row>

          <v-row class="mt-0">
            <v-col cpls="12">
              <TableImg
                :head="headers"
                :data="datas"
                :search="search"
                v-on:edititem="handleDialogEdit"
                v-on:deleteitem="handleDeleteDialog"
              />
            </v-col>
          </v-row>
        </base-material-card>

        <SupplierCRUD
          :dialog.sync="dialog"
          @handledata="handleSupplier"
          :myObj="obj"
          :category.sync="category"
        />

        <DialogDelete
          :dialogDelete.sync="dialogDelete"
          :deleteObj="deleteObj"
          @handleDelete="handleDeleteConfirm"
        />

        <SupplierCategory
          :categoryDialog.sync="categoryDialog"
          :category.sync="category"
          @handleDone="handleDone"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
//TODO Missing Batch Upload

import {
  supplierList,
  supplierTypeList,
  supplierDelete,
  supplierUpset,
} from "@/api/basicdata";

function FilterCategory(mainCategory, categories, list) {
  if (categories != undefined) {
    for (let i = 0; i < categories.length; i++) {
      list.push({
        text: mainCategory + categories[i].name,
        value: categories[i].id,
        type_code: categories[i].tree_code,
      });
      if (categories[i].items != null) {
        FilterCategory(
          mainCategory + categories[i].name + " / ",
          categories[i].items,
          list
        );
      }
    }
  }
  return list;
}

function changeNullItems(cloneCategory) {
  if (cloneCategory.items == null) {
    cloneCategory.items = [];
  } else {
    for (let i = 0; i < cloneCategory.items.length; i++) {
      changeNullItems(cloneCategory.items[i]);
    }
  }
}

const newObj = () => {
  return {
    id: "",
    type_code: "",
    company: "",
    province: "",
    city: "",
    district: "",
    address: "",
    tel: "",
    fax: "",
    contact: "",
    email: "",
    gen_time: "",
    bank_account: "",
    bank_name: "",
    bank_user: "",
    bank_swift_code: "",
    rating: "",
    bank_country: "",
    follow: "",
    note: "",
    is_inner: false,
    supplier_server: "",
    order_num: 0,
    order_price: 0,
    payed_num: 0,
    payed_price: 0,
  };
};

const newUpdata = () => {
  return {
    id: "",
    company: "",
    province: "",
    city: "",
    district: "",
    address: "",
    tel: "",
    fax: "",
    contact: "",
    email: "",
    bank_account: "",
    bank_name: "",
    bank_user: "",
    type_code: "",
    note: "",
    is_inner: false,
    supplier_server: "",
    bank_swift_code: "", //
    rating: "",
    bank_country: "",
  };
};

const newSearch = () => {
  return {
    type_code: "",
    skip: 0,
    limit: 2000,
    key: "",
  };
};

export default {
  name: "SupplierManagement",
  components: {
    TableImg: () => import("@/components/tableImg"),
    SupplierCRUD: () => import("@/components/purchase/supplierCRUD"),
    DialogDelete: () => import("@/components/deleteDialog"),
    PageNavigation: () => import("@/components/pageNavigation"),
    SupplierCategory: () => import("@/components/purchase/supplierCategory"),
  },
  data() {
    return {
      dialog: false,
      dialogDelete: false,
      categoryDialog: false,
      search: "",
      items: [
        { text: this.$vuetify.lang.t("$vuetify.home") },
        { text: this.$vuetify.lang.t("$vuetify.purchase") },
        { text: this.$vuetify.lang.t("$vuetify.supplier") },
      ],
      datas: [],
      category: [],
      cloneCategory: [],
      headers: [
        {
          text: this.$vuetify.lang.t("$vuetify.itemNo"),
          value: "itemNo",
          width: "100",
          sortable: false,
          align: "center",
        },
        {
          text: this.$vuetify.lang.t("$vuetify.companyName"),
          value: "company",
        },
        {
          text: this.$vuetify.lang.t("$vuetify.contactPerson"),
          value: "contact",
          sortable: false,
          width: "200",
        },
        {
          text: this.$vuetify.lang.t("$vuetify.phone"),
          value: "tel",
          width: "160",
          sortable: false,
        },
        {
          text: this.$vuetify.lang.t("$vuetify.addr"),
          value: "address",
          sortable: false,
        },
        {
          text: this.$vuetify.lang.t("$vuetify.qty1"),
          value: "order_num",
          sortable: false,
          align: "center",
        },
        {
          text: this.$vuetify.lang.t("$vuetify.paidAmount"),
          value: "order_price",
          width: "180",
          sortable: false,
          align: "center",
        },
        {
          text: this.$vuetify.lang.t("$vuetify.opt"),
          value: "SupplierActions",
          sortable: false,
          align: "center",
          width: "150",
        },
      ],
      searchForm: newSearch(),
      obj: newObj(),
      deleteObj: newObj(),
    };
  },
  methods: {
    getData() {
      this.category = [];
      this.cloneCategory = [];

      supplierList(this.searchForm)
        .then((data) => {
          if (data.items != null) {
            this.datas = [...data.items];

            for (var i = 0; i < this.datas.length; i++) {
              this.datas[i].itemNo = i + 1;
            }
          } else {
            this.$toast.info("No Data Found", { timeout: 1500 });

            this.datas = [];
          }
        })
        .catch((err) => console.log(err));

      supplierTypeList()
        .then((res) => {
          this.cloneCategory = [...res.items];

          this.cloneCategory.forEach((c) => changeNullItems(c));

          this.category = FilterCategory("", this.cloneCategory, []);

          this.category.push({ text: "All", value: -1, type_code: -1 });
        })
        .catch((err) => {
          console.log("Supplier Type List Error", err);
        });
    },
    handleDialog() {
      this.dialog = true;

      this.obj = newObj();

      this.category = this.category.filter((p) => p.type_code != -1);
    },
    batchUpload() {},
    handleSupplier(obj) {
      this.dialog = false;

      supplierUpset(obj)
        .then(() => this.getData())
        .catch((err) => console.log("New Supplier: ", err));
    },
    handleDone() {
      this.getData();
    },
    handleDialogEdit(obj) {
      if (this.permissionCheck("modify")) {
        this.dialog = true;
        this.obj = obj;

        for (var i = 0; i < this.cloneCategory.length; i++) {
          if (this.cloneCategory[i].tree_code === this.obj.type_code) {
            var categoryName = this.cloneCategory[i].name;
            this.obj.categoryName = categoryName;
          }
        }

        this.category = this.category.filter((p) => p.type_code != -1);
      }
    },
    handleDeleteDialog(obj) {
      this.dialogDelete = true;
      this.deleteObj = obj;
    },
    handleDeleteConfirm(obj) {
      this.dialogDelete = false;
      supplierDelete(obj.id)
        .then(() => {
          this.datas.splice(this.datas.indexOf(obj), 1);
        })
        .catch((err) => console.log("Supplier Management Delete Error", err));
    },
    handleCategoryFilter(item) {
      if (item.type_code == -1) {
        this.searchForm = newSearch();
      } else {
        this.searchForm.type_code = item.type_code;
      }

      this.getData();
    },
  },
  mounted() {
    this.getData();
  },
  watch: {
    categoryDialog() {
      if (this.categoryDialog) {
        this.category = this.category.filter((p) => p.type_code != -1);
      }
    },
  },
};
</script>